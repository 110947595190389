<template>
    <div>
        <el-dialog :visible.sync="sta.show" title="公共模板" width="800px">
            <div class="row">
                <div class="col-md-12">
                    <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                        :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">
                        <el-table-column type="index" label="序号" width="50" align="center" />
                        <el-table-column prop="Template_Name" label="模板名称" width="300" align="center" />
                        <el-table-column prop="AllTitelCount" label="题目数" align="center" />
                        <el-table-column label="创建时间" align="center" width="120">
                            <template slot-scope="scope">
                                {{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="Publish_User_Name" label="创建人" align="center" />

                        <el-table-column label="操作" align="center" width="120">
                            <template slot-scope="scope">
                                <el-button type="text" @click="tempDetail(scope.row)">详情</el-button>
                                <el-button type="text" @click="importTemp(scope.row)">导入学校</el-button>
                            </template>
                        </el-table-column>
                    </wgrid>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="close">关 闭</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="temp.show" :title="temp.title" width="800px">
            <el-collapse v-model="temp.activeNames">
                <el-collapse-item :name="pindex" v-for="(item,pindex) in temp.detail.Details" :key="item.ID">
                    <template slot="title">
                        <b>{{(pindex+1)+'：'+item.Parent_Title}}</b>
                    </template>
                    <div v-for="(child,cindex) in item.Children" :key="child.ID">
                        ({{cindex+1}})：{{child.Child_Title}} [{{child.Child_Score_Max}}]
                    </div>
                </el-collapse-item>
            </el-collapse>
            <div slot="footer">
                <el-button icon="el-icon-close" @click="temp.show=false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Wgrid from "@/components/wgrid";
    export default {
        components: { Wgrid },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                    },
                    ls: [],
                    total: 0,
                    loading: false
                },
                sta: {
                    show: false,
                    loading: false
                },
                temp: {
                    show: false,
                    detail: {},
                    activeNames: [0],
                    title: ''
                }
            }
        },
        methods: {
            getList() {
                let self = this
                self.grid.loading = true
                this.whale.remote.getCollection({
                    url: "/api/School/PFM/PfmTempalteSetting/GetPublicTemplate",
                    data: this.grid.sea,
                    completed: function (its, n) {
                        self.grid.loading = false;
                        self.grid.ls = its
                        self.grid.total = n
                    }
                })
            },
            loadPage() {
                this.grid.sea.PAGE_INDEX
            },
            init() {
                this.sta = { show: true, loading: false }
                this.getList()
            },
            close() {
                this.sta = { show: false }
            },
            importTemp(item) {
                let self = this;
                this.$confirm("确定要导入该模板吗？", "撤消", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    self.whale.remote.getResult({
                        url: "/api/School/PFM/PfmTempalteSetting/ImportTemp?tempId=" + item.ID,
                        completed: function () {
                            self.$message.success('导入成功！')
                            self.close()
                            self.$emit("close");
                        }
                    })
                }).catch(() => { })
            },
            tempDetail(item) {
                console.log(item.ID)
                let self = this
                self.temp.title = item.Template_Name
                this.whale.remote.getResult({
                    url: "/api/School/PFM/PfmTempalteSetting/Get?Id=" + item.ID,
                    completed: function (its) {
                        self.temp.show = true
                        self.temp.activeNames = [0]
                        self.temp.detail = its.DATA
                        console.log('its', its.DATA)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.el-collapse-item__content {
        padding-left: 20px;
    }
</style>